/* @format */
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

import { navigate } from "gatsby";

import Seo from "../../components/seo";
import ToolbarHeader from "../../components/ToolbarHeader";

import { useCommonStyles } from "../../styles";
import { useLoginService } from "@sa/react-login/useLoginContext";

import Button from "@material-ui/core/Button";

export default function Login({ redirect }) {
    const classes = useCommonStyles();
    const loginService = useLoginService();
    const [error, setError] = useState(null);
    console.log("I have a redirect", redirect);
    const handleLogin = useCallback(async () => {
        try {
            setError(null);
            await loginService.login({ login: "test1", password: "test1" });
            navigate(redirect, { ireplace: true });
        } catch (err) {
            setError(err);
        }
    }, [loginService, redirect]);

    return (
        <>
            <Seo title="Anmeldung" />
            <ToolbarHeader title="Anmeldung" />
            <main className={classes.content}>
                <p>Nur ein Dummy Login....</p>
                <Button variant="outlined" onClick={handleLogin}>
                    Perform Test login
                </Button>
                {error ? <p style={{ color: "red" }}>{error.message}</p> : null}
            </main>
        </>
    );
}

Login.propTypes = {
    redirect: PropTypes.string
};
Login.defaultProps = {
    redirect: "/user/profile"
};
