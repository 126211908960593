/* @format */
import React from "react";

import Seo from "../../components/seo";
import ToolbarHeader from "../../components/ToolbarHeader";
import useLoginContext from "@sa/react-login/useLoginContext";

import { useCommonStyles } from "../../styles";

export default function Profile() {
    const classes = useCommonStyles();
    const loginContext = useLoginContext();
    return (
        <>
            <Seo title="Nutzprofil" />
            <ToolbarHeader title="Nutzerprofil" />
            <main className={classes.content}>
                <p>Dummy User Profilre....</p>
                <pre>{JSON.stringify(loginContext.getUserInfo(), null, 4)}</pre>
            </main>
        </>
    );
}
