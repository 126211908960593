/* @format */
import React from "react";
//import { Link } from "gatsby";

import _ from "lodash-core";

import Layout from "../../components/layout";
//import ToolbarHeader from "../../components/ToolbarHeader";
//import Seo from "../../components/seo";

import { Router } from "@reach/router";

import PrivateRoute from "../../components/login/PrivateRoute";

import Login from "../../content/user/Login";
import Profile from "../../content/user/Profile";

const UserPage = ({
    location //eslint-disable-line react/prop-types
}) => {
    const redirect = _.get(location, "state.redirect");
    console.log("I read a redirect from location", location, redirect);
    return (
        <Layout>
            <Router>
                <Login path="/user/login" redirect={redirect} />
                <PrivateRoute path="/user/profile" component={Profile} />
            </Router>
        </Layout>
    );
};

export default UserPage;
